.game-clue{
    min-height: 100vh;
    width: 100%;
    display: flex;
    padding: 130px 50px;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transition-delay: 0.01s;
    -o-transition-delay: 0.01s;
    transition-delay: 0.01s;
}

body.orange .game-clue{
    background-color: var(--color-orange-light);
}

body.blue .game-clue{
    background-color: var(--color-blue-light);
}

body.yellow .game-clue{
    background-color: var(--color-yellow-light);
}

body.pink .game-clue{
    background-color: var(--color-pink-light);
}

.game-clue img{
    position: fixed;
    top: 35px;
    right: 30px;
}

.game-clue p{
    text-align: center;
    font-size: 24px;
}

.game-clue p span{
    background: -webkit-linear-gradient(135deg, var(--purple-dark), var(--purple-light));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}