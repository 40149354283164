@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  src:url(./assets/fonts/Gotham-Book.otf) format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  src:url(./assets/fonts/Gotham-Medium.otf) format('opentype');
  font-style: normal;
  font-display: swap;
} 

@font-face {
  font-family: 'Gotham';
  font-weight: 600;
  src:url(./assets/fonts/Gotham-Bold.otf) format('opentype');
  font-style: normal;
  font-display: swap;
} 

@font-face {
  font-family: 'Azeret Mono';
  font-weight: 300;
  src:url(./assets/fonts/AzeretMono-Light.ttf) format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Azeret Mono';
  font-weight: 400;
  src:url(./assets/fonts/AzeretMono-Regular.ttf) format('truetype');
  font-style: normal;
  font-display: swap;
} 

@font-face {
  font-family: 'Azeret Mono';
  font-weight: 600;
  src:url(./assets/fonts/AzeretMono-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-display: swap;
} 

*{
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

:after,:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

body{
  font-family: 'Gotham', Helvetica, sans-serif;
}

:root {
  --purple-dark: #47297F;
  --purple-light: #832A89;
  --blue-ultralight: #E4F0F1;
  --blue-ligth: #95CDD3;
  --onboarding-blue: #86C6CD;
  --onboarding-light-blue: #DCEEF1;
  --blue-ultralight: #E7F0F1;
  --blue-dark: #6ABAC2;
  --green-light: #318D9F;
  --disable-color: #00000040;
  --correct-color: #2FA943;
  --correct-color-light: #2FA94320;
  --error-color: #D02E2E;
  --error-color-light: #D02E2E20;
  --clue-color: #FFDD00;
  --clue-color-light: #FFDD0030;
  --border-color: #763D8D;
}

a, button, .gradientButton{
  cursor:pointer;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerColumn{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.col {
  align-items: center;
  padding: 0px 5px;
}

.row {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.col-10 {
  width: 10%;
}
.col-20 {
  width: 20%;
}

.col-30 {
  width: 30%;
}

.col-40 {
  width: 40%;
}

.col-50 {
  width: 50%;
}

.col-60 {
  width: 60%;
}

.col-70 {
  width: 70%;
}

.col-80 {
  width: 80%;
}

.col-90 {
  width: 90%;
}

.col-100 {
  width: 100%;
}

.m-25{
  padding: 0 25px;
}

.j-space{
  justify-content: space-between;
}

.j-left {
  justify-content: left;
}

.j-right {
  justify-content: right;
}

.j-space{
  justify-content: space-between;
}

.a-start {
  align-items: flex-start;
}

.a-end {
  align-items: flex-end;
}

.a-center {
  align-items: center;
}

.a-baseline {
  align-items: baseline;
}

.d-none {
  display: none;
}

.a-end{
  align-items: flex-end;
}

h1 {
  font-size: 22px;
  font-weight: 600;
}

p, a{
  font-weight: 400;
  font-size: 18px;
}

a{
  text-decoration: none;
}

.App-logo {
  pointer-events: none;
}

.open{
  z-index: 10;
  opacity: 1;
  visibility: visible;
}

button{
  -webkit-appearance: none;
  all: unset;
  cursor:pointer;
}

.buttonLink {
  opacity: 90%;
  padding: 10px 41.5px;
  border-radius: 12px;
}

.gradientButton {
  color: white;
  padding: 15px 8px;
  text-align: center;
  border-radius: 12px;
  font-weight: 400;
  border: 2px solid transparent;
  position: relative;
  /* overflow: hidden; */
  width: calc(100% - 50px);
  z-index: 1;
}

.gradientButton a{
  color: white;
}

.gradientButton:not(.outlined):before {
  content: '';
  display: block;
  background: -webkit-linear-gradient(135deg, var(--purple-dark), var(--purple-light));
  position: absolute;
  left: -2px;
  top: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  border-radius: 12px;
}

.gradientButton.disable:before{
  background: var(--disable-color);
}

.gradientButton.disable{
  pointer-events: none;
}

.gradientButton.outlined{
  background: -webkit-linear-gradient(135deg, var(--purple-dark), var(--purple-light));
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--border-color);
  color: var(--border-color);
  border: 2px solid var(--border-color);
}

.gradientButton.outlined.disable{
  -webkit-text-fill-color: initial;
  border-color: var(--disable-color);
  color: var(--disable-color);
  background: none;
  pointer-events: none;
}

.gradientLink{
  background: -webkit-linear-gradient(135deg, var(--purple-dark), var(--purple-light));
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--border-color);
  color: var(--border-color);
  font-weight: 400;
  text-decoration: none;
}

.gradientLink.disabled{
  -webkit-text-fill-color: initial;
  background: none;
  color: var(--disable-color);
  pointer-events: none;
}

.gradientBlue{
  background: -webkit-linear-gradient(90deg, var(--blue-dark), var(--blue-ligth));
}

input:focus{
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
