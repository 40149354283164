body.orange .game-bottom-buttons{
    background-color: var(--color-orange-light);
}

body.blue .game-bottom-buttons{
    background-color: var(--color-blue-light);
}

body.yellow .game-bottom-buttons{
    background-color: var(--color-yellow-light);
}

body.pink .game-bottom-buttons{
    background-color: var(--color-pink-light);
}

body.end .game-bottom-buttons{
    background-color: var(--onboarding-light-blue);
}

.game-bottom-buttons{
    width: 100%;
    padding: 15px 25px 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
}

body.orange .game-bottom-buttons:before{
    background: linear-gradient(to top, var(--color-orange-light), #FEEDD900);
    pointer-events: none;
}

body.blue .game-bottom-buttons:before{
    background: linear-gradient(to top, var(--color-blue-light), #D5E9F700);
    pointer-events: none;
}

body.yellow .game-bottom-buttons:before{
    background: linear-gradient(to top, var(--color-yellow-light), #FBF5D300);
    pointer-events: none;
}

body.pink .game-bottom-buttons:before{
    background: linear-gradient(to top, var(--color-pink-light), #FDE2EE00);
    pointer-events: none;
}

body.end .game-bottom-buttons:before{
    background: linear-gradient(to top, var(--onboarding-light-blue), #DCEEF100);
    pointer-events: none;
}

.game-bottom-buttons:before{
    content: "";
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    top: -60px;
    left: 0;
    z-index: 3;
}

.game-bottom-buttons a{
    display: block;
    font-size: 16px;
}

.question-buttons{
    width: 100%;
    color: #ffffff;
    display: flex;
}

/* "PISTA" BUTTON */

.question-buttons button:first-child{
    width: calc(40%);
    margin-right: 15px;
}

.lock-open-icon{
    width: 15px;
    height: auto;
    margin-left: 5px;
}

/* "RESPONDER" BUTTON */

.question-buttons button:last-child{
    width: calc(60% - 15px);
}

.game-bottom-buttons .finished, .game-bottom-buttons .not-started{
    display: block;
    width: 100%;
}

.game-bottom-buttons .not-started button{
    width: 100%;  
}