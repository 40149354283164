.count-down{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

/* COLOR FONDO */

body.orange .count-down{
    background-color: var(--color-orange);
}

body.blue .count-down{
    background-color: var(--color-blue);
}

body.yellow .count-down{
    background-color: var(--color-yellow);
}

body.pink .count-down{
    background-color: var(--color-pink);
}

/* CÍRCULO */

.count-down div{
    width: 120px;
    height: 120px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

body.orange .count-down div{
    background-color: var(--color-orange-light);
}

body.blue .count-down div{
    background-color: var(--color-blue-light);
}

body.yellow .count-down div{
    background-color: var(--color-yellow-light);
}

body.pink .count-down div{
    background-color: var(--color-pink-light);
}

/* TEXTO */

.count-down div span{
    mix-blend-mode: multiply;
    font-weight: 600;
    font-size: 50px;
}

body.orange .count-down div span{
    color: var(--color-orange);
}

body.blue .count-down div span{
    color: var(--color-blue);
}

body.yellow .count-down div span{
    color: var(--color-yellow);
}

body.pink .count-down div span{
    color: var(--color-pink);
}