.account{
  width: 100%;
}

/* .account form{
    display: flex;
    flex-direction: column;
    align-items: center;
} */

/* .account form label{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    text-align: left;
    border-radius: 12px;
    padding: 14px;
} */

/* .account form input[type=text] {
    border: none;
    font-size: 18px;
} */

.account a{
    font-weight: 600;
    font-size: 18px !important;
}

.bottom-button{
    width: 100%;
    display: flex;
    padding: 15px 25px 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: #E7F0F1;
}


.bottom-button:before{
    content: "";
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    top: -60px;
    left: 0;
    z-index: 10;
    background: linear-gradient(to top, #E7F0F1, #E7F0F100);
}

.bottom-button button{
    width: 100%;
    display: block;
}


/* .account form input[type=submit] {
    border: none;
    background: transparent;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 16px;
} */

/* @media screen and (max-height: 816px) { 
.account a{
    margin-top: 0px;
}   
} */

  