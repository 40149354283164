:root{
    --color-orange: #FCC27F;
    --color-orange-light: #FEEDD9;
    --color-blue: #74B6E6;
    --color-blue-light: #D5E9F7;
    --color-yellow: #F2DD6D;
    --color-yellow-light: #FBF5D3;
    --color-pink: #F89DC8;
    --color-pink-light: #FDE2EE;
}

body.orange{
    background-color: var(--color-orange);
}

body.blue{
    background-color: var(--color-blue);
}

body.yellow{
    background-color: var(--color-yellow);
}

body.pink{
    background-color: var(--color-pink);
}

body.end{
    background-color: var(--onboarding-blue);
}

