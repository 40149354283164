.game-header{
    width: 100%;
    padding: 25px 25px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.top-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 18px;
    padding: 0 5px;
}

.game-header .top-info h5 span{
    margin: 0 2px 0 0;
}

.game-header .top-info h5 span:first-child{
    font-weight: 400;
}

.top-info h5{
    font-size: 18px;
    font-weight: 300;
}

.top-info a {
    display: block;
}

.time-counter{
    display: flex;
    justify-content: space-between;
    background-color: #ffffff20;
    padding: 15px 20px;
    border-radius: 12px;
    border: 2px solid transparent;
    margin-bottom: 25px;
}

.time-counter.error{
    animation: wrong-answer-counter ease-in-out 2s;
}

.resume-part p{
    font-size: 16px;
}

.resume-part p span{
    font-weight: 400;
}

@keyframes wrong-answer-counter{
    0%{
        border-color: var(--error-color);
    }
    85%{
        border-color: var(--error-color);
    }
    100%{
        border-color: transparent;
    }
}

.time-counter p{
    font-weight: 400;
    font-size: 20px;
    font-family: 'Azeret Mono', 'Gotham', Helvetica, sans-serif;
}

.time-counter .svg_icon path{
    fill: #ffffff95;
}

.time-counter .svg_icon{
    opacity: 1;
}

.time-counter .svg_icon.running{
    animation: running-time 1.6s linear 1.6s infinite;
}

@keyframes running-time{
    0%{
        opacity: 0;
    }
    49%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}

/* RESUME PART */

.resume-part{
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.resume-part .text{
    width: 100%;
    /* width: 55%; */
    background-color: #ffffff;
    border-radius: 12px;
    padding: 15px;
    position: relative;
    z-index: 2;
    height: fit-content;
}

.resume-part .text:before{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: -23px;
    /* background-color: #ffffff; */
    border: 12px solid transparent;
    border-left-color: #ffffff;
}

.resume-part .text h6{
    mix-blend-mode: multiply;
    font-size: 14px;
    margin-bottom: 6px;
}

body.orange .resume-part .text h6{
    color: var(--color-orange); 
}

body.blue .resume-part .text h6{
    color: var(--color-blue); 
}

body.yellow .resume-part .text h6{
    color: var(--color-yellow); 
}

body.pink .resume-part .text h6{
    color: var(--color-pink); 
}

body.end .resume-part .text h6{
    color: var(--onboarding-blue); 
}

.resume-part .text p{
    font-weight: 300;
}

.resume-part .text p span{
    font-weight: 400;
}

.resume-part img{
    width: auto;
    height: 230px;
}