body.orange .game-questions{
    background-color: var(--color-orange-light);
}

body.blue .game-questions{
    background-color: var(--color-blue-light);
}

body.yellow .game-questions{
    background-color: var(--color-yellow-light);
}

body.pink .game-questions{
    background-color: var(--color-pink-light);
}

.game-questions{
    min-height: calc(100vh - 143.5px);
    padding: 25px 25px 150px;
    margin-top: 143.5px;
    position: relative;
    z-index: 2;
}

.game-questions .question-answers h4{
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    color: #332F2B;
}