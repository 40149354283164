body.instructionsPage{
    background-color: var(--onboarding-blue);
}
.instructions-page{
    padding: 10vh 0 40px;
    background-color: var(--onboarding-blue);
}

.instructions-page .header{
    padding: 0 40px;
}

.instructions-page .header h1{
    color: #21879A;
    text-align: center;
    font-size: 25px;
    margin-top: 9vh;
    font-weight: 600;
}

.instructions-page .section{
    color: white;
    text-align: center;
    padding: 0 35px;
    margin-bottom: 50px;
}

.instructions-page .section p{
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 20px;
}

.instructions-page .section p span{
    font-weight: 600;
}

.instructions-page a{
    font-size: 16px;
}

.instructions-page .section div{ 
    margin-top: 10vh;
}

.instructions-page .section div img{ 
    margin-bottom: 5vh;
}

/* .instructions-page .section a{
    display: block;
    margin: 95px 0 50px 0;
    width: 100%;
} */

