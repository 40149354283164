.game-resume{
    width: 100%;
    background-color: #ffffff;
    padding: 35px 25px 150px;
    margin-top: 376px;
    position: relative;
    min-height: 100vh;
    z-index: 3;
}

.game-resume .question-list{
    display: flex;
    flex-direction: column;
}

.game-resume .question-list > div{
    margin-bottom: 45px;
}

.game-resume .question-list > div:last-child{
    margin-bottom: 0;
}

.game-resume h5{
    font-size: 16px;
    font-weight: 400;
    color: #00000090;
}

.game-resume h4{
    font-size: 20px;
    font-weight: 300;
    margin: 11px 0 20px;
}

.game-resume .answer, .game-resume .clue{
    width: 100%;
    padding: 15px 17px;
    background-color: palegoldenrod;
    border-radius: 12px;
    border: 2px solid transparent;
}

.game-resume .answer p{
    font-size: 17px;
    font-weight: 300;
}

.game-resume .answer .information, .game-resume .clue{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.game-resume .clue{
    margin-bottom: 0;
}

.game-resume .information h6, .game-resume .information p, .game-resume .clue h6, .game-resume .clue p{
    font-size: 15px;
    font-weight: 400;
}

.game-resume .result-list{
    display: flex;
    flex-direction: column;
}

.game-resume .result-list > div{
    margin-bottom: 10px;
}

.game-resume .result-list > div:last-child{
    margin-bottom: 0;
}

/* DID YOU KNOW  */
.game-resume .didYouknow{
    margin-top: 45px;
}

.game-resume .didYouknow h3{
    margin: 35px 0 15px;
    font-size: 24px;
    font-weight: 400;
}

.game-resume .didYouknow p{
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
}

.game-resume .didYouknow p a{
    background: -webkit-linear-gradient(135deg, var(--purple-dark), var(--purple-light));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    font-size: 20px;
}

.game-resume .didYouknow p:last-child{
    margin-bottom: 0;
}



/* ERROR */

.game-resume .answer.error{
    border-color: var(--error-color);
    background-color: var(--error-color-light);
}

.game-resume .answer.error .information{
    color: var(--error-color);
}

/* CORRECT */

.game-resume .answer.correct{
    border-color: var(--correct-color);
    background-color: var(--correct-color-light);
}

.game-resume .answer.correct .information{
    color: var(--correct-color);
}

.game-resume .answer.correct .information p{
    display: none;
}

/* CLUE */

.game-resume .clue{
    background-color: var(--clue-color-light);
    border-color: var(--clue-color);
}

/* BOTÓN */

.game-resume .bottom-button{
    width: 100%;
    padding: 15px 25px 30px;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.game-resume .bottom-button:before{
    content: "";
    display: block;
    width: 100%;
    height: 60px;
    background: linear-gradient(to top, #ffffff, #ffffff00);
    position: absolute;
    top: -60px;
    left: 0;
    z-index: 3;
}

.game-resume .bottom-button a{
    display: block;
    font-size: 16px;
    width: 100%;
}

.game-resume .bottom-button .gradientButton{
    width: 100%;
}