.slidders a{
  font-size: 16px;
}

.slidders p{
  font-size: 18px;
  margin-bottom: 14px;
}

.slidders p span{
  font-weight: 600;
}

.slidders{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  padding: 9vh 30px 150px;
  position: relative;
  color: white;
  background-color: var(--onboarding-blue);
 
}

.slidders .intro-image{
  height: 110px;
}

.intro-image{
  height: 130px;
  width: auto;
  margin-bottom: 45px;
}

.reum-image{
  height: 170px;
  margin-bottom: 30px;
  margin-top: calc(56px + 5vh);
}

.slidders .logoScapeReum{
  position: absolute;
  top: 9vh;
}

.slidders p{
  font-weight: 300;
}

.slidders .skipIntro, .slidders .gradientButton{
  position: absolute;
  bottom: 6vh;
  width: 100%;
  display: block;
}

#slidder1 p:nth-child(3){
  font-size: 20px;
}

/*swiper*/

#swiperReum .swiper-horizontal>.swiper-pagination-bullets {
  position: fixed;
  bottom: 110px;
  left: 50%;
  z-index: 999;
}


#swiperReum .swiper-pagination-bullet-active {
  background: -webkit-linear-gradient(135deg, var(--purple-dark), var(--purple-light));
  opacity: 100%;
}


#swiperReum .swiper-pagination-bullet {
  background-color: #bbe5e8;
  opacity: 100%;
}

#swiperReum .swiper, #swiperReum .swiper-container{
  z-index: inherit;
}

/* @media screen and (max-height: 816px) {  
  .slidders{
    justify-content: flex-start;
    padding-top: 20vh;
  }

  #slidder1{
    padding-top: 6vh;
  } 

  #trophy{
    padding-top: 12vh;
  }

  .slidders .logoScapeReum{
    width: 80%;
    margin-bottom: 10px;
  }

  .slidders img{
    height: 100px;
    margin-bottom: 20px;
  }
  

} */

@media screen and (min-height: 750px) {  

  .reum-image{
    height: 190px;
    margin: 45px 0;
  }

  .slidders{
    padding: 9vh 30px 130px;
  }

  .slidders .intro-image{
  height: 125px;
}

}