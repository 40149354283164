.startGame{
    height: 70px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
    background: #ffffff;
}

.startGame .instructions{
    width: 40%;
    border-top: 2px solid var(--border-color);
    font-size: 15px;
}

.startGame .instructions :first-child{
    margin-bottom: 4px;
}

.startGame .gradientButton{
    width: 60%;
    font-size: 22px;
}

.startGame button{
    width: calc(60% - 20px) !important;
}

.startGame .gradientButton:not(.outlined):before{
    border-radius: 0;
}

/* TOOLTIP */

.tooltip-overlay{
    width: 100%;
    min-height: calc(100vh - 70px);
    /* padding: 0 25px 25px; */
    display: flex;
    align-items: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transition-delay: 0.05s;
    -o-transition-delay: 0.05s;
    transition-delay: 0.05s;
    background-color: #00000050;
}

.tooltip{
    /* width: 100%; */
    width: calc(100% - 50px);
    background-color: #ffffff;
    padding: 12px 16px;
    border-radius: 10px;
    /* position: relative; */
    position: fixed;
    bottom: 95px;
    left: 25px;
    z-index: -9;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transition-delay: 0.05s;
    -o-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

.tooltip:before{
    content: "";
    display: block;
    position: absolute;
    bottom: -23px;
    left: 11.5%;
    /* background-color: #ffffff; */
    border: 12px solid transparent;
    border-top-color: #ffffff;
}

.tooltip p{
    font-weight: 400;
}
