body.rankingPage{
    background: -webkit-radial-gradient(var(--blue-dark), var(--green-light));
    width: 100%;
    height: 100vh;
}

.ranking-top5{
    display: flex;
    justify-content: space-between;
}

.ranking-top5.general{
    flex-direction: row;
}

.ranking-top5.diario{
    flex-direction: row-reverse;
}

/* RANKING CONTENT */

.ranking-top5 .ranking-content{
    width: 52%;
    height: 100vh;
    background-color: var(--blue-ultralight);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ranking-top5.general .ranking-content{
    padding: 110px 144px 110px 110px;
}

.ranking-top5.diario .ranking-content{
    padding: 110px 110px 110px 130px;
}

.ranking-top5 .ranking-content .escape-reum-logo{
    /* width: 485px; */
    width: 440px;
}

.ranking-top5.diario .ranking-content .title{
    margin-bottom: 110px;
}

.ranking-top5.general .ranking-content .title{
    margin-bottom: 70px;
}

.ranking-top5 .ranking-content .title h1, .ranking-top5 .ranking-content .title h2{
    text-transform: uppercase;
    font-weight: 600;
}

.ranking-top5.diario .ranking-content .title h1, .ranking-top5 .ranking-content .title h2{
    font-size: 52px !important;
}

.ranking-top5.general .ranking-content .title h1, .ranking-top5 .ranking-content .title h2{
    font-size: 50px !important;
}

.ranking-top5 .ranking-content .title h1{
    color: var(--border-color);
}

.ranking-top5 .ranking-content .title h1 span{
    color: var(--green-light);
}

.ranking-top5 .ranking-content .title h2{
    color: var(--green-light);
}

.ranking-top5.diario .ranking-content .top5{
    height: 650px;
}

.ranking-top5.general .ranking-content .top5{
    height: 665px;
}

.ranking-top5 .ranking-content .top5 .explanation-message{
    font-size: 30px;
    font-weight: 300;
    color: #767676;
}

.ranking-top5.general .ranking-content .top5 .played-days{
    font-weight: 300;
    color: #767676;
    font-size: 22px;
    padding-top: 12px;
}

/* .ranking-top5 .ranking-content .ranking-list{
    height: 400px;
} */

.ranking-top5.diario .ranking-content .ranking-list .first, .ranking-top5 .ranking-content .ranking-list .not-first{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ranking-top5.general .ranking-content .ranking-list .first, .ranking-top5 .ranking-content .ranking-list .not-first{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ranking-top5 .ranking-content .ranking-list .position-info{
    display: flex;
    /* align-items: center; */
}

.ranking-top5 .ranking-content .ranking-list .first .group-info{
    display: flex;
    flex-direction: column;
}

.ranking-top5.diario .ranking-content .ranking-list .not-first .group-info{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ranking-top5.general .ranking-content .ranking-list .not-first .group-info{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.ranking-top5 .ranking-content h3, .ranking-top5 .ranking-content h4{
    color: var(--border-color);
    margin-right: 40px;
}

.ranking-top5 .ranking-content h3{
    font-size: 52px;
}

.ranking-top5 .ranking-content h4{
    font-size: 42px;
}

.ranking-top5 .ranking-content .ranking-list .first img{
    width: 52px;
    height: auto;
}

.ranking-top5.general .ranking-content .ranking-list .first img{
    margin-top: 6px;
}

.ranking-top5.diario .ranking-content .ranking-list .not-first{
    margin-top: 20px;
}

.ranking-top5.general .ranking-content .ranking-list .not-first{
    margin-top: 25px;
}

.ranking-top5 .ranking-content .ranking-list .group-info p{
    font-size: 32px;
}

.ranking-top5.general .ranking-content .ranking-list .not-first .group-info p{
    margin-bottom: 8px;
}

.ranking-top5 .ranking-content .ranking-list .first .group-info .ranking-time{
    color: var(--border-color);
    font-family: 'Azeret Mono', 'Gotham', Helvetica, sans-serif;
}

.ranking-top5 .ranking-content .ranking-list .first .ranking-time, .ranking-top5 .ranking-content .ranking-list .not-first .ranking-time{
    font-size: 28px;
    margin-top: 5px;
    font-family: 'Azeret Mono', 'Gotham', Helvetica, sans-serif;
}

.ranking-top5 .ranking-content .ranking-list hr{
    opacity: .5;
    color: #BAC9D2;
    height: 2px;
    margin: 30px 0;
}

/* CTA-LOGOS */

.ranking-top5 .cta-logos{
    width: 46%;
    height: 100vh;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
}

.ranking-top5.general .cta-logos{
    align-items: flex-end;
}

.ranking-top5.diario .cta-logos{
    align-items: flex-start;
}

/* CTA */

.ranking-top5 .cta-logos .cta{
    color: #ffffff;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.ranking-top5.general .cta-logos .cta{
    align-items: flex-end;
}

.ranking-top5.diario .cta-logos .cta{
    align-items: flex-start;
}

.ranking-top5.general .cta-logos div{
    text-align: right;
}

.ranking-top5.diario .cta-logos div{
    text-align: left;
}

.ranking-top5 .cta-logos .cta h2{
    font-weight: 300;
    font-size: 50px;
}

.ranking-top5 .cta-logos .cta p{
    font-weight: 300;
    font-size: 30px;
    margin: 34px 0 58px;
    width: 73%;
}

.ranking-top5 .cta-logos .cta span{
    font-weight: 600;
}

/* QR */

.ranking-top5 .cta-logos .cta img{
    width: 220px;
    height: 220px;
}

/* LOGOS */

.ranking-top5 .cta-logos .logos .logo-XLIX {
    width: 144px;
    height: auto;
}

.ranking-top5 .cta-logos .logotipo-SER{
    width: 318px;
    margin-top: 24px;
}

/* DRA. IMAGE */

.ranking-top5 .dra-image{
    width: 376px;
    height: auto;
    position: fixed;
    bottom: -1px;
    z-index: 99;
}

.ranking-top5.general .dra-image{
    left: 46.5%;
}

.ranking-top5.diario .dra-image{
    left: 32%;
}