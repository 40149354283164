.backButton{
    margin-bottom: 30px;
    position: relative;
    top: -20px;
}

.backButton .gradientLink{
    font-size: 18px !important;
}

.backButton a img{
    margin-right: 4px;
    position: relative;
    top: 1px;
}