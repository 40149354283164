@font-face {
    font-family: 'Impact';
    src: url(../assets/fonts/Impact.woff2) format('woff2'),
         url(../assets/fonts/Impact.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }  

body.orange .game-block-intro{
    background-color: var(--color-orange-light);
}

body.blue .game-block-intro{
    background-color: var(--color-blue-light);
}

body.yellow .game-block-intro{
    background-color: var(--color-yellow-light);
}

body.pink .game-block-intro{
    background-color: var(--color-pink-light);
}

body.end .game-block-intro{
    background-color: var(--onboarding-light-blue);
}

.game-block-intro{
    min-height: calc(100vh - 143.5px);
    padding: 62px 30px 150px;
    text-align: center;
    margin-top: 143.5px;
    position: relative;
    z-index: 2;
}

body.orange .game-block-intro h1{
    color: var(--color-orange);
}

body.blue .game-block-intro h1{
    color: var(--color-blue);
}

body.yellow .game-block-intro h1{
    color: var(--color-yellow);
}

body.pink .game-block-intro h1{
    color: var(--color-pink);
}

body.end .game-block-intro h1{
    color: var(--onboarding-blue);
}

.game-block-intro h1{
    font-size: 35px;
    width: 90%;
    display: block;
    margin: auto;
    margin-bottom: 35px;
    font-family: 'Impact';
    text-transform: uppercase;
    font-weight: 100;
    mix-blend-mode: multiply;
}

.game-block-intro p{
    margin-bottom: 20px;
    font-family: 'Gotham';
    font-weight: 300;
}

.game-block-intro p span{
    font-weight: 400;
}

.game-block-intro p:last-child{
    margin-bottom: 0;
}

/* DAY END */

.game-block-intro .end-content p:first-child{
    font-size: 19px;
}

.game-block-intro .end-content p:last-child{
    font-size: 20px;
}