.question-progress .progress-bar{
    width: 100%;
    height: 12px;
    background-color: #ffffff;
    border-radius: 30px;
}

.progress-bar span{
    height: 100%;
    display: block;
    border-radius: 30px;
    mix-blend-mode: multiply;
}

/* PROGRESS COLOR */

body.orange .progress-bar span{
    background-color: var(--color-orange);
}

body.blue .progress-bar span{
    background-color: var(--color-blue);
}

body.yellow .progress-bar span{
    background-color: var(--color-yellow);;
}

body.pink .progress-bar span{
    background-color: var(--color-pink);;
}

/* PROGRESS WIDTH */

.progress-bar span{
    transition: all 0.35s ease-in-out;
}

.progress-bar.q-1 span{
    width: 20%;
}

.progress-bar.q-2 span{
    width: 40%;
}

.progress-bar.q-3 span{
    width: 60%;
}

.progress-bar.q-4 span{
    width: 80%;
}

.progress-bar.q-5 span{
    width: 100%;
}

/* TEXT */

.question-progress h6{
    font-size: 16px;
    font-weight: 400;
    margin: 15px 0 25px;
    color: #00000090;
}