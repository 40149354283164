/*home Page*/
.homePage{
    background-color: var(--blue-ultralight);
    /* min-height: 100vh; */
}

body.home-page{
    background-color: var(--blue-ultralight);
}

.home-page .content{
    background-color: #ffffff65;
    min-height: calc(100vh - (22vh + 32.5px));
    margin-top: calc(22vh + 32.5px);
    padding: 32px 0 100px;
}

.home-page h2{
   color: var(--green-light);
   font-size: 22px;
}

.home-page hr{
    opacity: .5;
    color: #BAC9D2;
    height: 2px;
}

.homeTeam{
    padding: 0 25px;
}

.homeTeam .generalDescription{
    justify-content: space-between;
    align-items: center;
}

.homeTeam .generalDescription img{
    margin-left: 8px;
}

.home-page .homeTeam ul{
    list-style-type: none;
    margin-top: 2.5vh;
}

.home-page .homeTeam ul li{
    margin-bottom: 1.2vh;
    font-size: 18px;
}

.home-page .homeTeam ul li:first-child{
    font-weight: 400;
}

.home-page .homeTeam hr{
    margin: 2.5vh 0;
}

.home-page .homeTeam li{
    font-weight: 300;
}

.home-page .homeTeam .code{
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
}

/*Ranking*/ 

.home-page .ranking h2{
    font-size: 20px;
    margin: 10px 0 5px;
}

.home-page .ranking .rankingPosition{
    margin-bottom: 25px;
}

.home-page .ranking .rankingPosition div{
    display: flex;
    margin: 15px 0 20px;
}

.home-page .ranking .rankingPosition p{
    font-size: 28px;
    position: relative;
    top: 3px;
}

.home-page .ranking .rankingPosition img{
    margin-left: 12px;
}

.home-page .ranking .explanation-message, .home-page .homeResult .explanation-message {
    font-weight: 300;
    color: #767676;
    font-family: 'Gotham';
}

.home-page .rankingTime{
    background-color: white;
    padding: 5px 5px;
    width: 100%;
    border-radius: 16px;
    text-align: center;
    margin-bottom: 35px;
}

.home-page .rankingTime button{
   width: 50%;
}

.home-page .bestPoint{
    align-items: flex-end;
}

.home-page .bestPoint img{
    margin-left: 7px;
}

.home-page .rankingTime .selected{
    background: #D5E8EA;
}

.home-page .ranking .bestPoints{
    justify-content: space-between;
    margin-bottom: 2vh;
}

.home-page .ranking .rankingNumber{
    margin-right: 16px;
    color: var(--border-color);
    font-size: 20px;
    font-weight: 600;
}

.home-page .ranking .top5{
    width: 100%;
}

.home-page .ranking .top5 .ranking-time, .home-page .ranking .affected .ranking-time{
    font-weight: 300;
    font-size: 16.5px;
    font-family: 'Azeret Mono', 'Gotham', Helvetica, sans-serif;
}

.home-page .ranking .played-days{
    font-weight: 300;
    color: #767676;
    font-size: 16px;
    padding-top: 6px;
}

.home-page .ranking .top5 p{
    max-width: 110px;
    word-wrap: break-word;
    margin-bottom: 6px;
}

.home-page .ranking .first{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.home-page .ranking .first span{
    display: block;
    margin-top: 4px;
}

.home-page .ranking hr{
    margin: 2.5vh 0;
}

.home-page .ranking .container{
    width: 100%;
    border-bottom: 2px solid #D7DEE3;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.home-page .ranking .score{
    justify-content: space-between;
}

.home-page .ranking .lastScore .score{
    margin-bottom: 26px;
}

/*Resultados*/
.homeResult {
    display: flex;
    flex-direction: column-reverse;
}

.homeResult .clock{
    margin-left: 20px;
}

.homeResult .clock p{
    font-family: 'Azeret Mono', 'Gotham', Helvetica, sans-serif;
    font-weight: 400;
}

.homeResult .date div img{
    margin-right: 10px;
}

.homeResult .date .lastDate, .homeResult .date .dayDate{
    margin-bottom: 20px;
}

.homeResult .date .graphicDate{
    margin-bottom: 24px;
}

.homeResult .date .blockDate{
    margin-bottom: 14px;
}

.home-page .homeResult .ranking-icon img{
    width: 22px;
}

.home-page .homeResult .graphicDate .ranking-icon img{
    position: relative;
    bottom: 2px;
}

.home-page .homeResult .graphicDate .clock img{
    position: relative;
    bottom: 1px;
}

.home-page .homeResult .blockDate span{
    color: var(--border-color);
    font-size: 17px;
}

.home-page .homeResult .blockDate p:first-child{
    font-size: 17px;
}

.home-page .homeResult .blockDate p:last-child{
    font-size: 17px;
    font-weight: 300;
    font-family: 'Azeret Mono', 'Gotham', Helvetica, sans-serif;
}

.home-page .homeResult .block{
    font-size: 18px;
    font-weight: 400;
}

.home-page .homeResult hr{
    margin: 2.8vh 0;
}


/* SHOW/HIDE WITH DROPDOWN */

.dayDate, .lastScore{
    display: none;
    /* animation: display 2s linear; */
}

.dayDate.opened, .lastScore.opened{
    display: block;
}




@media (min-width: 365px) { 
    .home-page .ranking .top5 p{
        max-width: 205px;
    }
} 

@media (min-width: 768px) { 
    .home-page .ranking .top5 p{
        max-width: 240px;
    }
} 


