.logInPages h2, .logInPages p{
    text-align: center;
}

.logInPages h2{
    margin-bottom: 9px;
    font-size: 22px;
}

.logInPages span{
    color: var(--green-light);
}

.log p{
    font-weight: 300;
    margin-bottom: 15px;
}

body.loginPage{
    background-color: var(--blue-ultralight);
}

.logInPages{
    background-color: var(--blue-ultralight);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8vh 25px 30px;
    position: relative;
}

.logInPages.buttonYes{
    padding: 8vh 25px 170px;
}

.logInPages.buttonYesLog{
    padding: 8vh 25px 200px;
}

.logInButtons{
    width: 100%;
}

/* .message-error{
    font-weight: 400 !important;
    font-size: 16px;
    margin-top: 22px;
    color: var(--border-color);
} */

/* .logInButton{
    position: absolute !important;
    bottom: 30px;
    width: calc(100% - 50px);
}  */

.logInPages .peopleImage{
    margin-bottom: 2.5vh;
}


/*LogIn 1*/
.LogIn1{
    justify-content: center;
    align-items: center;
}

.logIn1 p{
    font-weight: 300 !important;
}

#logoColor{
    margin: 0 0 6vh;
}

.account{
    width: 100%;
    margin-top: 2.5vh;
}
  
form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* form label{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    text-align: left;
    border-radius: 12px;
    margin: 6px 0;
    padding: 14px;
} */

form label{
    position: absolute;
    left: 14px;
    top: 14px;
}

form div{
    position: relative;
    margin-top: 12px;
    width: 100%;
}

form div span{
    display: none;
}


/* 
form label:first-child{
    margin-top: 0;
} */

form div input[type=text] {
    width: 100%;
    padding: 22px 18px;
    border: none;
    font-weight: 600;
    color: #2B2B2B;
    font-size: 18px;
    text-align: left;
    border-radius: 12px;
    border: 2px solid transparent;
}

.input-error-message.hasLabel .error, .input-error-message.noLabel .error{
    border-color: var(--error-color) !important;
}

.input-error-message.hasLabel span{
    margin: 10px 0 0 5px;
    display: block;
    color: var(--error-color);
    font-weight: 300;
}

.input-error-message.noLabel span{
    text-align: center;
    margin-top: 10px;
    display: block;
    color: var(--error-color);   
    font-weight: 300; 
}


form div.hasLabel input[type=text] {
    padding: 40px 14px 14px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #89898990;
    opacity: 1; /* Firefox */
}

form input[type=text]:focus, form input[type=text]:active{
    border-color: var(--border-color);
}

form input:-internal-autofill-selected{
    background-color: #ffffff;
    color: #2B2B2B;
}

.logInPages .gradientLink {
    font-weight: 400;
    font-size: 16px;
    /* margin-bottom: 30px; */
    text-align: center;
}

.bottom-button{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 25px 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    color: #ffffff;
    background-color: #E7F0F1;
}

.bottom-button p{
    color: #000000;
    font-size: 16px;
    display: inline;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
}

.bottom-button p button{
    width: fit-content;
}

.bottom-button:before{
    content: "";
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    top: -60px;
    left: 0;
    z-index: 10;
    background: linear-gradient(to top, #E7F0F1, #E7F0F100);
}

.bottom-button button, .bottom-button a{
    width: 100%;
    display: inline-block;
}

.bottom-button a{
    font-size: 16px;
}


/* form input[type=submit] {
    border: none;
    background: transparent;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 16px;
} */

.Log h2 span {
    color: #318D9F;
}

/*LogIn 1.1*/

#LogIn1-1 .text {
    margin: 2.5vh 0;
}

#LogIn1-1 h2{
    font-weight: 600;
    margin-top: 20px;
}

#LogIn1-1 .logInButtons .buttonLink{
    background: #B9D9DC98;
    display: block;
    width: 100%;
    padding: 27px 0;
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    color: #000000;
}

#LogIn1-1 .logInButtons :first-child{
    margin-top: 0;
}

/*LogIn 1.2*/
#LogIn1-2 .text{
    margin: 20px 0 2.5vh 0;
    width: 90%;
}

#LogIn1-2 form{
    width: 100%;
}

#LogIn1-2 form div{
    margin-top: 0px;
}

#LogIn1-2 .text :first-child, #LogIn1-3 .text :first-child{
    font-weight: 400;
}

#LogIn1-2 .account, #LogIn1-3 .account{
    margin-top: 0;
}

#LogIn1-2 .backButton, #LogIn1-3 .backButton{
    align-self: flex-start;
}

/*LogIn 1.3*/
#LogIn1-3 .text{
    margin: 2.5vh 0;
}

.log .inputSingleLine{
    margin-top: 0;
}


.log .inputSingleLine input[type=text]{
    margin-top: 0;
    font-size: 24px;
    text-align: center;
}


/*LogIn 1.2.1*/
#copyLabel{
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 14px 18px;
    background: white;
    border-radius: 12px;
    margin: 5vh 0 30px;
    position: relative;
}

#copyLabel img{
    width: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
}

#copyLabel p{
    width: 100%;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 400;
}

#LogIn1-2-1 img{
    display: block;
    margin: auto;
}

#LogIn1-2-1 .create .text, #LogIn1-2-1 .join h2{
    margin-top: 4vh;
}

.join{
    margin-top: 5vh;
}

#groupCode{
    text-transform: uppercase;
}

/* COPY TO CLIPBOARD */

.create .copied::before, .create .not-copied::before{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    border-radius: 12px;
    position: absolute;
    animation: copy-top-clipboard-message ease-in-out 2s;
    opacity: 0;
}

.create .copied::before{
    content: "¡Código copiado!";
    background-color: var(--border-color);
}

.create .not-copied::before{
    content: "No se ha podido copiar";
    background-color: #6D949B;
}

@keyframes copy-top-clipboard-message{
    0%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

@media screen and (max-height: 816px) { 
    /* .logInPages{
        justify-content: flex-start;
    } */
/* 
    .logInFlexStart{
        padding-top: 10vh;
    } */

    /* #logoColor {
        margin: 6vh 0 5vh 0;
    } */
/* 
    h1 {
        margin-bottom: 11px;
    } */

    /* .logInPages .peopleImage{
        margin-top: 5vh;
    } */

    /* .logInPages a{
        margin-top: 30px;
    } */
/* 
    .logInButton{
        bottom: 3vh;
    }

    #LogIn1-1 .logInButtons .buttonLink{
        margin: 5px 0;
    } */
    

}

