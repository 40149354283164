.game-modal-overlay{
    min-height: 100vh;
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transition-delay: 0.01s;
    -o-transition-delay: 0.01s;
    transition-delay: 0.01s;
    background-color: #00000050;
}

.modal{
    width: calc(100% - 50px);
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 25px;
    z-index: -9;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-transition-delay: 0.01s;
    -o-transition-delay: 0.01s;
    transition-delay: 0.01s;
    padding: 24px 18px;
    text-align: center;
    border-radius: 16px;
    -webkit-transform: translateX(0) translateY(-50%) translateZ(0);
    -ms-transform: translateX(0) translateY(-50%) translateZ(0);
    transform: translateX(0) translateY(-50%) translateZ(0);
}

.modal p{
    font-size: 18px;
    font-weight: 400;
    margin: 24px 0 28px;
}

.modal p span{
    font-weight: 400;
}

.modal-buttons{
    width: 100%;
    display: flex;
}

.modal-buttons :first-child{
    width: calc(50% - 30px);
    margin-right: 15px;
}

.modal-buttons :last-child{
    width: calc(50% - 7.5px);
}

.modal-buttons .gradientButton{
    font-size: 16px;
}

.modal-buttons a{
    width: 100%;
}