.homeHeader{
    margin-bottom: 2.5vh;
    background-color: var(--blue-ultralight);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
}

.homeHeader .headerImage{
    height: 22vh;
}

.homeHeader .headerLinks{
    justify-content: space-between;
    width: 100%;
    padding: 0 25px;
    text-align: center;
    position: relative;
}

.homeHeader .headerLinks button{
    width: calc(100%/3);
    padding-bottom: 12px;
    font-size: 17px;
    position: relative;
    color: #000000;
}

.homeHeader .headerLinks button.selected{
    background: -webkit-linear-gradient(135deg, var(--purple-dark), var(--purple-light));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.homeHeader .headerLinks button.selected::after{
    background: linear-gradient(135deg, var(--purple-dark), var(--purple-light));
    bottom: 0px;
    left: 0;
    content:'';
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
}