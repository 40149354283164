/* SELECT TYPE */

/* CONTENEDOR PREGUNTA */

.select-answer-list button{
    display: block;
    margin-top: 12px;
    background-color: #ffffff90;
    /* width: 100%; */
    width: calc(100% - 35px);
    padding: 15px;
    border: 2px solid transparent;
    border-radius: 12px;
    word-wrap: break-word;
    position: relative;
    font-weight: 300;
    text-decoration: none;
    color: #000000;
    overflow: hidden;
}

.select-answer-list button:first-child{
    margin-top: 24px;
}

/* WRONG ANSWER */

.select-answer-list button.error{
    border-color: var(--error-color);
    pointer-events: none;
}

.select-answer-list button span{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    opacity: 0;
    margin: auto;
    color: var(--error-color);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #F3BDAE;
}

.select-answer-list button.error span{
    animation: wrong-answer-select ease-in-out 2s;
    pointer-events: none;
}

@keyframes wrong-answer-select{
    0%{
        opacity: 1;
    }
    85%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

/* CORRECT ANSWER */

.select-answer-list button.correct{
    border-color: var(--correct-color);
    background-color: #2FA94325;
    pointer-events: none;
}

/* SELECTED */

/* .select-answer-list button:focus, .select-answer-list button:active*/
.select-answer-list .selected{ 
    border-color: #763D8D;
    background-color: #ffffff;
}




/* INPUT TYPE */

.input-answer{
    margin-top: 24px;
}

.input-answer div{
    width: fit-content;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.input-answer input[type="number"]{
    padding: 15px;
    width: 100%;
    border: 2px solid transparent;
    font-size: 16px;
    border-radius: 10px;
    color: #00000080;
    font-weight: 400;
    background-color: #ffffff90;
}

/* SELECTED */

.input-answer input[type="number"]:active,
.input-answer input[type="number"]:focus {
    border-color: var(--border-color);
    background-color: #ffffff;
    color: #000000;
}

/* WRONG ANSWER */

.input-answer input[type="number"].error{
    animation: wrong-answer-input linear 1.5s;
}

.input-answer span{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 18px;
    font-weight: 400;
    opacity: 0;
    border-radius: 10px;
    margin: auto;
    color: var(--error-color);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #F3BDAE;
}

.input-answer.error span{
    animation: wrong-answer-input ease-in-out 2s;
    pointer-events: none;
}

@keyframes wrong-answer-input{
    0%{
        opacity: 1;
    }
    85%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}