
.skipIntro{
    position: fixed;
    bottom: 0;
    z-index: 300;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: var(--onboarding-blue);
    padding: 15px 25px 30px;
}

.skipIntro:before{
    content: "";
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    top: -60px;
    left: 0;
    z-index: 300;
    background: linear-gradient(to top, var(--onboarding-blue), #86C6CD00); 
}

.skipIntro a.gradientButton{
    font-size: 16px;
    display: block;
    width: 100%;
}

.skipIntro .gradientLink{
    bottom: 10px;
    position: relative;
}
