.headerOnBoarding{
  background-color: #6ABAC2;
  display: flex;
  /* height: 135px; */
  padding: 16px 25px 14px;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.headerOnBoarding .ser{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerOnBoarding .ser p{
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
}
